import { Link } from "react-router-dom";
import { React, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "animate.css";
import ProfileSidebar from "./common/ProfileSidebar";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";

const Careers = () => {
  const [show, setShow] = useState(false);
  const [errorsValidation, setErrorsValidation] = useState({
    existing_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [passwordData, setpasswordData] = useState({
    existing_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleUpdate = async (e) => {
    e.preventDefault();

    let formIsValid = true;
    let errors = {};

    if (!passwordData.new_password) {
      formIsValid = false;
      errors.new_password = "New password is required";
    } else if (
      !/(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])/.test(passwordData.new_password)
    ) {
      formIsValid = false;
      errors.new_password =
        "New password must contain at least one uppercase letter and one special character.";
    }

    if (!passwordData.confirm_password) {
      formIsValid = false;
      errors.confirm_password = "Confirm password is required";
    } else if (passwordData.confirm_password !== passwordData.new_password) {
      formIsValid = false;
      errors.confirm_password = "Passwords do not match.";
    }

    if (!passwordData.existing_password) {
      formIsValid = false;
      errors.existing_password = "Existing password is required";
    }

    setErrorsValidation(errors);

    if (formIsValid) {
      try {
        const upadatedData = await axiosClient.post("users/change-password", {
          existing_password: passwordData.existing_password,
          new_password: passwordData.new_password,
        });
        if (!upadatedData.data.error) {
          Swal.fire({
            icon: "success",
            title: "User update successful",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>
                <img src="/images/brd_rowwo.png" alt="" /> Settings
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>

              <div className="col-lg-9">
                <div className="form_boxx profilss">
                  <h4 class="br_space">Change Password</h4>
                  <div className="form_arar mt-0">
                    <div className="mang_und_listst mt-0">
                      <form onSubmit={handleUpdate}>
                        <div className="row">
                          <div className="col-lg-6">
                            <div class="form-group">
                              <label>Existing Password</label>
                              <input
                                type="password"
                                class="form-control"
                                name="existing_password"
                                placeholder="Enter your existing password"
                                onChange={(e) => {
                                  setpasswordData({
                                    ...passwordData,
                                    existing_password: e.target.value,
                                  });
                                }}
                                value={passwordData.existing_password}
                              />
                              {errorsValidation.existing_password && (
                                <div className="error">
                                  {errorsValidation.existing_password}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div class="form-group">
                              <label>
                                New Password (At least 8 Characters)
                              </label>
                              <input
                                type="password"
                                class="form-control"
                                name="new_password"
                                placeholder="Enter your new password"
                                onChange={(e) => {
                                  setpasswordData({
                                    ...passwordData,
                                    new_password: e.target.value,
                                  });
                                }}
                                value={passwordData.new_password}
                              />
                              {errorsValidation.new_password && (
                                <div className="error">
                                  {errorsValidation.new_password}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div class="form-group">
                              <label>Confirm Password</label>
                              <input
                                type="password"
                                class="form-control"
                                name="confirm_password"
                                placeholder="Confirm password"
                                onChange={(e) => {
                                  setpasswordData({
                                    ...passwordData,
                                    confirm_password: e.target.value,
                                  });
                                }}
                                value={passwordData.confirm_password}
                              />
                              {errorsValidation.confirm_password && (
                                <div className="error">
                                  {errorsValidation.confirm_password}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {/* <div className="col-lg-6">
                            <div class="form-group">
                              <label>Security Pin</label>
                              <p className="viewss">
                                View security Password : 19**{" "}
                                <Link to="">
                                  <img src="/images/sm_eye.png" alt="" />
                                </Link>
                              </p>
                            </div>
                          </div> */}

                          <div className="col-lg-12 mt-5">
                            <div class="form-group">
                              <button
                                type="submit"
                                value="Update Profile"
                                class="sub_mitess"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;
