import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import axiosClient from "../../Services/Axios/axios";
import { Modal } from "react-bootstrap";

const CommanImagePDf = ({ name, setState, state }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [image, setImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [stream, setStream] = useState(null);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isMobileUserAgent =
      /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
        userAgent
      );
    const isSmallScreen = window.innerWidth <= 768;

    if (isMobileUserAgent || isSmallScreen) {
      setDeviceType("Phone");
    } else {
      setDeviceType("Computer");
    }
  }, []);

  const startCamera = async (e) => {
    e.preventDefault();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment", // Use "user" for the front camera
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
      setStream(stream);
    } catch (error) {
      console.error("Error accessing the camera:", error);
      alert("Could not access the camera. Please check permissions.");
    }
  };

  const captureImage = async (e, name) => {
    e.preventDefault();
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;

      context.drawImage(videoRef.current, 0, 0);

      const imageData = canvasRef.current.toDataURL("image/png");

      const blob = await fetch(imageData).then((res) => res.blob());

      const formData = new FormData();
      formData.append("image", blob, "captured_image.png");

      try {
        const responce = await axiosClient.post("/users/uploadimg", formData);
        if (!responce.data.error) {
          setState({
            ...state,
            [name]: responce.data.file,
          });
        } // Log the server's response
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
    setIsModalOpen(false);
    setImage(null);
  };
  const handleChange1 = async (e, name, type) => {
    try {
      const file = e.target.files[0];

      // Check if the file size exceeds 5MB
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > maxFileSize) {
        Swal.fire({
          icon: "error",
          title: "File size exceeds 5MB",
          text: "Please upload a file smaller than 5MB.",
        });
        return; // Exit the function
      }

      const formData = new FormData();
      formData.append(`${type === "phone" ? "image" : "all_docs"}`, file);

      const responce = await axiosClient.post(
        `/users/${
          type === "phone" ? "uploadimg" : "uploadpdfImageDocExcelCSV"
        }`,
        formData
      );

      if (!responce.data.error) {
        setState({
          ...state,
          [name]: responce.data.file,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: responce.data.message,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Image is too large",
      });
    }
  };

  const handleClose1 = () => {
    setIsModalOpen(false);
    setImage(null);
    if (videoRef.current && stream) {
      // Stop all video tracks (this will stop the camera stream)
      stream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null; // Remove the video source
    }
  };
  console.log(state[name], "ddddd");
  return (
    <>
      <div className="d-flex">
        <input
          type="file"
          className="form-control"
          name={name}
          onChange={(e) => handleChange1(e, name)}
          style={{ width: "80%" }}
        />

        {deviceType === "Phone" && (
          <label className="upload-button" style={{ width: "20%" }}>
            Camera
            <input
              type="file"
              capture="environment"
              name={name}
              onChange={(e) => handleChange1(e, name, "phone")}
              style={{ display: "none" }}
            />
          </label>
        )}
        {deviceType === "Computer" && (
          <label
            style={{ width: "20%", height: "45px", padding: "8px 10px" }}
            className="upload-button"
            onClick={(e) => {
              setIsModalOpen(true);
              startCamera(e);
            }}
          >
            Camera
          </label>
        )}

        <Modal className="all_supportss sm_sizess" id="capture" show={isModalOpen} onHide={handleClose1}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <canvas ref={canvasRef} style={{ display: "none" }} />
            <video ref={videoRef} style={{ width: "100%", height: "100%" }} />
            <button className="cap_imgess" onClick={(e) => captureImage(e, name)}>
              Capture Image
            </button>
            {image && (
              <div>
                <h3>Captured Image:</h3>
                <img src={image} alt="Captured" style={{ width: "300px" }} />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
      {state[name]?.includes(".pdf") ? (
        <a target="_blank" download={state[name]} href={state[name]}>
          pdf
        </a>
      ) : (
        <img src={state[name]} />
      )}
    </>
  );
};

export default CommanImagePDf;
