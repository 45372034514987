import React, { useEffect, useRef, useState } from "react";
import ProfileSidebar from "../common/ProfileSidebar";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";

const AddCampaign = () => {
  const [formState, setFormState] = useState({
    donation_title: "",
    global_amount: "",
    donation_description: "",
    donation_category_id: "",
    donation_images: "",
    expiry_date: new Date(),
    other_images: [""],
    documents: [""],
  });
  const [categoryData, setCategoryData] = useState([]);
  const [buttonloader, setButtonloader] = useState(false);
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(null);
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const validate = () => {
    const newErrors = {};
    if (!formState.donation_title.trim())
      newErrors.donation_title = "Title is required.";
    if (!formState.global_amount.trim() || isNaN(formState.global_amount))
      newErrors.global_amount = "Valid amount is required.";
    if (!formState.donation_description.trim())
      newErrors.donation_description = "Description is required.";
    if (!formState.donation_category_id)
      newErrors.donation_category_id = "Category is required.";
    if (!formState.donation_images)
      newErrors.donation_images = "Cover image is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e, index, type) => {
    const { name, value, files } = e.target;

    setFormState({ ...formState, [name]: files ? files[0] : value });
  };

  const addMoreFields = (type) => {
    if (type === "gallery") {
      setFormState({
        ...formState,
        other_images: [...formState.other_images, ""],
      });
    } else if (type === "documents") {
      setFormState({ ...formState, documents: [...formState.documents, ""] });
    }
  };

  const removeField = (index, type) => {
    if (type === "gallery") {
      const updatedImages = formState.other_images.filter(
        (_, i) => i !== index
      );
      setFormState({ ...formState, other_images: updatedImages });
    } else if (type === "documents") {
      const updatedDocuments = formState.documents.filter(
        (_, i) => i !== index
      );
      setFormState({ ...formState, documents: updatedDocuments });
    }
  };

  const handleChange1 = async (e, index, type) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      const responce = await axiosClient.post("/users/uploadimg", formData);
      if (!responce.data.error) {
        if (type === "gallery") {
          const updatedImages = [...formState.other_images];
          updatedImages[index] = responce.data.file;
          setFormState({ ...formState, other_images: updatedImages });
        } else if (type === "documents") {
          const updatedDocuments = [...formState.documents];
          updatedDocuments[index] = responce.data.file;
          setFormState({ ...formState, documents: updatedDocuments });
        } else if (type === "donation_images") {
          setFormState({ ...formState, donation_images: responce.data.file });
        }
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Image is too large",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (validate()) {
        setButtonloader(true);
        const data = await axiosClient.post(
          `/campaigns/add-campaign`,
          formState
        );
        if (!data.data.error) {
          Swal.fire({
            icon: "success",
            title: data.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setFormState({
          ...formState,
          donation_title: "",
          global_amount: "",
          donation_description: "",
          donation_category_id: "",
          donation_images: "",
          expiry_date: new Date(),
          other_images: [""],
          documents: [""],
        });
        navigate("/campaign");
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clearing the file input
        }
        setButtonloader(false);
      }
    } catch (error) {
      console.log(error);
      setButtonloader(false);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const getAllData = async () => {
    try {
      const category = await axiosClient.get(
        `/campaigns/getCampaignsCategory?language=en`
      );
      if (!category.data.error) {
        setCategoryData(
          category.data.data.map((item) => {
            return {
              label: item.category_name,
              value: item.id,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea evnetsts profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="/profile">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" />{" "}
                <Link to="/campaign">Campaigns</Link>
                <img src="/images/brd_rowwo.png" alt="" />{" "}
                <Link to="/add-campaign">Add Campaigns</Link>
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>
              <div className="col-lg-9">
                <div className="form_boxx profilss">
                  <h4 className="br_space">Add Campaign</h4>
                  <div className="form_arar mt-0">
                    <div className="mang_und_listst mt-0">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="donation_title"
                                    placeholder="Enter title"
                                    value={formState.donation_title}
                                    onChange={handleChange}
                                  />
                                  {errors.donation_title && (
                                    <small className="text-danger">
                                      {errors.donation_title}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>About Campaign</label>
                                  <textarea
                                    className="form-control"
                                    rows="4"
                                    name="donation_description"
                                    placeholder=""
                                    value={formState.donation_description}
                                    onChange={handleChange}
                                  ></textarea>
                                  {errors.donation_description && (
                                    <small className="text-danger">
                                      {errors.donation_description}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Amount</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="global_amount"
                                    placeholder="Enter amount"
                                    value={formState.global_amount}
                                    onChange={handleChange}
                                  />
                                  {errors.global_amount && (
                                    <small className="text-danger">
                                      {errors.global_amount}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Category</label>
                                  <select
                                    className="form-control"
                                    name="donation_category_id"
                                    value={formState.donation_category_id}
                                    onChange={handleChange}
                                  >
                                    {categoryData &&
                                      categoryData.map((item) => {
                                        return (
                                          <option value={item.value}>
                                            {item.label}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  {errors.donation_category_id && (
                                    <small className="text-danger">
                                      {errors.donation_category_id}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group relatives">
                                  <label>Deadline</label>
                                  <DatePicker
                                    selected={formState.expiry_date}
                                    onChange={(date) =>
                                      setFormState({
                                        ...formState,
                                        expiry_date: date,
                                      })
                                    }
                                    minDate={new Date()}
                                  />
                                  <img src="/images/calenders.png" alt="" />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group" id="browsers">
                                  <label>Cover Image</label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="donation_images"
                                    ref={fileInputRef}
                                    onChange={(e) =>
                                      handleChange1(e, 0, "donation_images")
                                    }
                                  />
                                  {errors.donation_images && (
                                    <small className="text-danger">
                                      {errors.donation_images}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div
                                  className="form-group"
                                  id="add_more_filess"
                                >
                                  <div className="both_managess">
                                    <label>Gallery Image</label>
                                    <div className="ad_buttons">
                                      <Link
                                        to="#"
                                        onClick={() => addMoreFields("gallery")}
                                      >
                                        + Add More
                                      </Link>
                                    </div>
                                  </div>

                                  {formState.other_images.map(
                                    (image, index) => (
                                      <div
                                        className="row add_mor_lisstst"
                                        key={index}
                                      >
                                        <div className="col-lg-10 col-8">
                                          <div
                                            className="form-group mb-1"
                                            id="browsers"
                                          >
                                            <input
                                              type="file"
                                              className="form-control"
                                              ref={fileInputRef}
                                              onChange={(e) =>
                                                handleChange1(
                                                  e,
                                                  index,
                                                  "gallery"
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-2 col-4">
                                          <div className="ad_buttons remove_bnt">
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                removeField(index, "gallery")
                                              }
                                            >
                                              Remove
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div
                                  className="form-group"
                                  id="add_more_filess"
                                >
                                  <div className="both_managess">
                                    <label>Documents</label>
                                    <div className="ad_buttons">
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          addMoreFields("documents")
                                        }
                                      >
                                        + Add More
                                      </Link>
                                    </div>
                                  </div>

                                  {formState.documents.map((doc, index) => (
                                    <div
                                      className="row add_mor_lisstst"
                                      key={index}
                                    >
                                      <div className="col-lg-10 col-8">
                                        <div
                                          className="form-group mb-1"
                                          id="browsers"
                                        >
                                          <input
                                            type="file"
                                            className="form-control"
                                            ref={fileInputRef}
                                            onChange={(e) =>
                                              handleChange1(
                                                e,
                                                index,
                                                "documents"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-2 col-4">
                                        <div className="ad_buttons remove_bnt">
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              removeField(index, "documents")
                                            }
                                          >
                                            Remove
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-4">
                            <div className="form-group">
                              <button
                                type="submit"
                                value="Publish Event"
                                className="sub_mitess"
                                disabled={buttonloader}
                              >
                                {buttonloader ? (
                                  <ClipLoader color="#fff" size={"25"} />
                                ) : (
                                  "Publish Campaign"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddCampaign;
