import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ProfileSidebar from "./common/ProfileSidebar";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import ImageUploader from "./common/ImageUploader";
import { ClipLoader } from "react-spinners";
import CommanImagePDf from "./common/CommanImagePDf";

const Careers = () => {
  const [userNewData, setUserNewData] = useState({});
  const [nidDocImage, setNidDocImage] = useState({
    nidDocImage: "",
  });
  const [companyRegistration, setCompanyRegistration] = useState({
    companyRegistration: "",
  });
  const [NGOLicense, setNGOLicense] = useState({
    ngo_license: "",
  });
  const [loading, setLoading] = useState(false);
  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      let kycUpdate;
      if (userNewData && userNewData !== 5) {
        kycUpdate = {
          company_registration: companyRegistration.companyRegistration,
          ngo_license: NGOLicense.ngo_license,
        };
      } else {
        kycUpdate = {
          nidDocImage: nidDocImage.nidDocImage,
        };
      }
      console.log(kycUpdate);
      const upadatedData = await axiosClient.put(
        `users/update-user-${
          userNewData?.role_type === 5 || userNewData?.role_type === 7
            ? "kyc"
            : "kyb"
        }`,
        kycUpdate
      );
      if (!upadatedData.data.error) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "User kyc update successful",
        });
        handleProfile();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleProfile = async () => {
    const response = await axiosClient.get("/users/getByID");
    if (!response.data.error) {
      localStorage.setItem("userData", JSON.stringify(response.data.data));
    }
  };

  useEffect(() => {
    setUserNewData(JSON.parse(localStorage.getItem("userData")));
  }, []);

  useEffect(() => {
    setNidDocImage({ nidDocImage: userNewData?.nid_doc_image });
    setCompanyRegistration({
      companyRegistration: userNewData?.company_registration,
    });
    setNGOLicense({ ngo_license: userNewData?.ngo_license });
  }, [userNewData]);
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" />{" "}
                {userNewData?.role_type === 5 || userNewData?.role_type === 7
                  ? "KYC"
                  : "KYB"}
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>

              <div className="col-lg-9">
                <div className="form_boxx profilss">
                  <div className="form_arar mt-0">
                    <h4>
                      {userNewData?.role_type === 5 ||
                      userNewData?.role_type === 7
                        ? "KYC"
                        : "KYB"}
                    </h4>
                    <div className="mang_und_listst mt-0" id="two_arts_prt">
                      <form action="" onSubmit={handleUpdate}>
                        <div className="row">
                          {userNewData && userNewData.role_type === 5 && (
                            <>
                              {" "}
                              <div className="col-lg-6">
                                {/* <div className="mgs_titales">
                                  <h6 className="titaless">NID Image</h6>
                                  <ImageUploader
                                    setState={setNidDocImage}
                                    defaultImage={nidDocImage}
                                  />
                                  <p className="t_sizes">
                                    Recommended image size 150x150
                                  </p>
                                </div> */}
                                <div
                                  class="form-group quest_mngs"
                                  id="browsers"
                                >
                                  <label>
                                    <span class="area_txt">
                                      NID Image / Documents
                                    </span>
                                  </label>
                                  <CommanImagePDf
                                    name="nidDocImage"
                                    setState={setNidDocImage}
                                    state={nidDocImage}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-lg-4 col-6">
                         <div className="mgs_titales">
                           <h6 className="titaless">Address Proof</h6>
                           <ImageUploader
                             setState={setAddressDocImage}
                             defaultImage={addressDocImage}
                           />
                           <p className="t_sizes">
                             Recommended image size 150x150
                           </p>
                         </div>
                       </div> */}
                            </>
                          )}

                          {userNewData && userNewData.role_type !== 5 && (
                            <>
                              {" "}
                              <div className="col-lg-6">
                                {/* <div className="mgs_titales">
                                  <h6 className="titaless">
                                    Company Registration{" "}
                                  </h6>
                                  <ImageUploader
                                    setState={setCompanyRegistration}
                                    defaultImage={companyRegistration}
                                  />
                                  <p className="t_sizes">
                                    Recommended image size 150x150
                                  </p>
                                </div> */}
                                <div
                                  class="form-group quest_mngs"
                                  id="browsers"
                                >
                                  <label>
                                    <span class="area_txt">
                                      {" "}
                                      Company Registration
                                    </span>
                                  </label>
                                  <CommanImagePDf
                                    name="companyRegistration"
                                    setState={setCompanyRegistration}
                                    state={companyRegistration}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                {/* <div className="mgs_titales">
                                  <h6 className="titaless">NGO Licences </h6>
                                  <ImageUploader
                                    setState={setNGOLicense}
                                    defaultImage={}
                                  />
                                  <p className="t_sizes">
                                    Recommended image size 150x150
                                  </p>
                                </div> */}
                                <div
                                  class="form-group quest_mngs"
                                  id="browsers"
                                >
                                  <label>
                                    <span class="area_txt"> NGO Licences</span>
                                  </label>
                                  <CommanImagePDf
                                    name="ngo_license"
                                    setState={setNGOLicense}
                                    state={NGOLicense}
                                  />
                                </div>
                              </div>{" "}
                            </>
                          )}

                          <div className="col-lg-12 spacetop mt-5">
                            <div class="form-group">
                              <button
                                type="submit"
                                value="Update Profile"
                                class="sub_mitess"
                                disabled={loading}
                              >
                                {loading ? (
                                  <ClipLoader color="#fff" size={"15"} />
                                ) : (
                                  `Update ${
                                    userNewData?.role_type === 5 ||
                                    userNewData?.role_type === 7
                                      ? "KYC"
                                      : "KYB"
                                  }`
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;
