import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import ProfileSidebar from "../common/ProfileSidebar";
import DatePicker from "react-datepicker";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";

const UpdateEvent = () => {
  const [formState, setFormState] = useState({
    event_title: "",
    event_location: "",
    event_description: "",
    donation_category_id: "",
    event_date: "",
    event_time: "",
    other_images: [""],
    documents: [""],
  });
  const [errors, setErrors] = useState({});
  const [buttonloader, setButtonloader] = useState(false);
  const [image, setImage] = useState(null);
  const fileInputRef = useRef();

  const validate = () => {
    const newErrors = {};
    if (!formState.event_title.trim())
      newErrors.event_title = "Title is required.";
    if (!formState.event_location.trim())
      newErrors.event_location = "Location is required.";

    if (!formState.event_description.trim())
      newErrors.event_description = "Description is required.";
    if (!formState.event_date) newErrors.event_date = "Date is required.";
    if (!formState.event_time) newErrors.event_time = "Time is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const removeField = (index, type) => {
    if (type === "gallery") {
      const updatedImages = formState.other_images.filter(
        (_, i) => i !== index
      );
      setFormState({ ...formState, other_images: updatedImages });
    } else if (type === "documents") {
      const updatedDocuments = formState.documents.filter(
        (_, i) => i !== index
      );
      setFormState({ ...formState, documents: updatedDocuments });
    }
  };

  const addMoreFields = (type) => {
    if (type === "gallery") {
      setFormState({
        ...formState,
        other_images: [...formState.other_images, ""],
      });
    } else if (type === "documents") {
      setFormState({ ...formState, documents: [...formState.documents, ""] });
    }
  };
  const handleChange1 = async (e, index, type) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      const responce = await axiosClient.post("/users/uploadimg", formData);
      if (!responce.data.error) {
        if (type === "gallery") {
          const updatedImages = [...formState.other_images];
          updatedImages[index] = responce.data.file;
          setFormState({ ...formState, other_images: updatedImages });
        } else if (type === "documents") {
          const updatedDocuments = [...formState.documents];
          updatedDocuments[index] = responce.data.file;
          setFormState({ ...formState, documents: updatedDocuments });
        } else if (type === "donation_images") {
          setFormState({ ...formState, donation_images: responce.data.file });
        }
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Image is too large",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setButtonloader(true);
      if (validate()) {
      }
    } catch (error) {
      console.log(error);
      setButtonloader(false);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleChange = (e, index, type) => {
    const { name, value, files } = e.target;

    setFormState({ ...formState, [name]: files ? files[0] : value });
  };

  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">Event</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" /> Add Event
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>
              <div className="col-lg-9">
                <div className="form_boxx profilss">
                  <h4 className="br_space">Add Event</h4>
                  <div className="form_arar mt-0">
                    <div className="mang_und_listst mt-0">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12">
                                <div class="form-group">
                                  <label>Title</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="event_title"
                                    placeholder="Enter title"
                                    value={formState.event_title}
                                    onChange={handleChange}
                                  />
                                  {errors.event_title && (
                                    <small className="text-danger">
                                      {errors.event_title}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div class="form-group">
                                  <label>About Event</label>
                                  <textarea
                                    class="form-control"
                                    rows="4"
                                    cols="4"
                                    name="event_description"
                                    placeholder=""
                                    value={formState.event_description}
                                    onChange={handleChange}
                                  ></textarea>
                                  {errors.event_description && (
                                    <small className="text-danger">
                                      {errors.event_description}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div class="form-group">
                                  <label>Location</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="event_location"
                                    placeholder="Enter Location"
                                    value={formState.event_location}
                                    onChange={handleChange}
                                  />
                                  {errors.event_location && (
                                    <small className="text-danger">
                                      {errors.event_location}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div class="form-group relatives">
                                  <label>Date</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    name="event_time"
                                    placeholder="Choose time"
                                    value={formState.event_date}
                                    onChange={handleChange}
                                  />
                                  {/* <img src="/images/calenders.png" alt="" /> */}
                                  {errors.event_date && (
                                    <small className="text-danger">
                                      {errors.event_date}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div class="form-group">
                                  <label>Time</label>
                                  <input
                                    type="time"
                                    class="form-control"
                                    name="event_time"
                                    placeholder="Choose time"
                                    value={formState.event_time}
                                    onChange={handleChange}
                                  />
                                  {errors.event_time && (
                                    <small className="text-danger">
                                      {errors.event_time}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>Image</label>
                                  {formState.other_images.map(
                                    (image, index) => (
                                      <div className="row" key={index}>
                                        <div className="col-lg-10">
                                          <div
                                            className="form-group mb-1"
                                            id="browsers"
                                          >
                                            <input
                                              type="file"
                                              className="form-control"
                                              ref={fileInputRef}
                                              onChange={(e) =>
                                                handleChange1(
                                                  e,
                                                  index,
                                                  "gallery"
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-2">
                                          <div className="ad_buttons">
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                removeField(index, "gallery")
                                              }
                                            >
                                              Remove
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <div className="ad_buttons">
                                    <Link
                                      to="#"
                                      onClick={() => addMoreFields("gallery")}
                                    >
                                      + Add More
                                    </Link>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>Videos</label>
                                  {formState.documents.map((doc, index) => (
                                    <div className="row" key={index}>
                                      <div className="col-lg-10">
                                        <div
                                          className="form-group mb-1"
                                          id="browsers"
                                        >
                                          <input
                                            type="file"
                                            className="form-control"
                                            ref={fileInputRef}
                                            onChange={(e) =>
                                              handleChange1(
                                                e,
                                                index,
                                                "documents"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-2">
                                        <div className="ad_buttons">
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              removeField(index, "documents")
                                            }
                                          >
                                            Remove
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div className="ad_buttons">
                                    <Link
                                      to="#"
                                      onClick={() => addMoreFields("documents")}
                                    >
                                      + Add More
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-4">
                            <div class="form-group">
                              <button
                                type="submit"
                                value="Publish Event"
                                class="sub_mitess"
                              >
                                {buttonloader ? (
                                  <ClipLoader color="#fff" size={"25"} />
                                ) : (
                                  "Publish Event"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UpdateEvent;
