import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Link } from "react-router-dom";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";

const ScannerModal = ({ open, onClose }) => {
  const [tempData, setTempData] = useState("91");
  const [websiteData, setWebsiteData] = useState({});
  const [camScan, setCamScan] = useState(true);
  const [userDetail, setUserDetail] = useState({});
  const handleCheckIn = async () => {
    try {
      const response = await axiosClient.post(`/events/checkin`, {
        event_id: websiteData?.id,
      });
      if (response.data && !response.data.error) {
        Swal.fire({ icon: "success", title: "Event Checked In Successfully" });
        onClose();
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: "Something went wrong" });
      console.error("Check-in failed:", error);
    }
  };

  const handleCheckOut = async (id) => {
    try {
      const response = await axiosClient.post(`/events/checkout`, {
        event_id: id,
      });
      if (response.data && !response.data.error) {
        Swal.fire({ icon: "success", title: "Event Checked Out Successfully" });
      }
    } catch (error) {
      console.error("Check-in failed:", error);
    }
  };

  const getWebsiteData = async () => {
    try {
      const dataNew = userDetail?.EventBooking?.find((item) => item?.id === 91);
      if (dataNew) {
        setCamScan(false);
        if (dataNew?.booking_status === 0) {
          const response = await axiosClient.get(
            `/events/getEventsInfoByID/${+tempData}`
          );

          if (response.data && !response.data.error) {
            setWebsiteData(response.data.data);
          }
        }
        if (dataNew?.booking_status === 1) {
          Swal.fire({
            title: "Already Checked In",
            text: "You r already checked in, Do u want to Check Out?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              handleCheckOut(dataNew?.id);
            } else {
              onClose();
            }
          });
        }
        if (dataNew?.booking_status === 2) {
          Swal.fire({
            title: "Already Checked out",
            text: "You r already checked out.",
            icon: "warning",
            showCancelButton: true,
          });
          // Swal.fire({
          //   title: "Already Checked In",
          //   text: "You r already checked in, Do u want to Check Out?",
          //   icon: "warning",
          //   showCancelButton: true,
          //   confirmButtonColor: "#3085d6",
          //   cancelButtonColor: "#d33",
          //   confirmButtonText: "Yes",
          //   cancelButtonText: "No",
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     handleCheckOut(dataNew?.id);
          //   } else {
          //     onClose();
          //   }
          // });
        }
        if (dataNew?.booking_status === 3) {
          Swal.fire({
            title: "Already Checked In",
            text: "You r already checked in, Do u want to Check Out?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              handleCheckOut(dataNew?.id);
            } else {
              onClose();
            }
          });
        }
      } else {
      }
    } catch (error) {
      console.error("Error fetching website data:", error);
    }
  };

  useEffect(() => {
    if (
      tempData === "" ||
      tempData === undefined ||
      tempData === null ||
      tempData === "Not Found"
    ) {
      setCamScan(true);
    }
    getWebsiteData();
  }, [tempData, camScan]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    setUserDetail(data);
  }, []);
  return (
    <>
      <Modal
        className="all_supportss sm_sizess"
        id="ScanUpload1"
        show={open}
        onHide={onClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {camScan && (
            <div className="fromss_boxx">
              <div className="cm_box_area scan_fildss sc_blak_filess">
                <BarcodeScannerComponent
                  width={400}
                  height={300}
                  onUpdate={(err, result) => {
                    if (result) setTempData(result); // Update tempData on scan
                    else setTempData("");
                  }}
                />
              </div>
            </div>
          )}
          {!camScan && (
            <div className="fromss_boxx" id="sc_wel_evntss">
              <div className="cm_box_area scan_fildss sc_blak_filess">
                <div className="hedings">Welcome</div>
                <div className="bothss">
                  <div className="us_data_sc">
                    <h5>{websiteData?.title}</h5>
                    <div className="us_dat_timess">
                      <span className="datess">
                        <img src="/images/g_uil_calender.png" alt="" />
                        {dayjs(websiteData?.event_date).format("DD, MMM YYYY")}
                      </span>
                      <span className="datess">
                        <img src="/images/tabler_clock.png" alt="" />{" "}
                        {websiteData?.event_time} -{websiteData?.event_time_end}
                      </span>
                    </div>
                    <p>{websiteData?.short_title}</p>
                  </div>

                  <div className="us_mg_partss">
                    <img src={websiteData?.image} alt="" />
                  </div>
                </div>
                <div className="manga_data">
                  Thank you for participating in the event . you can check in
                  from below and your timing can be trackd through it
                </div>
                <div className="sc_btn_wel">
                  <Link onClick={handleCheckIn} to="">
                    Continue
                  </Link>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScannerModal;
