import { useEffect, useState } from "react";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const [loading, setLoading] = useState(false);
  const getPrivacyPolicyData = async () => {
    try {
      setLoading(true);
      const data = await axiosClient.get(`/web/getPrivacyPolicy?language=en`);
      if (!data.data.error) {
        setPrivacyPolicy(data.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getPrivacyPolicyData();
  }, []);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      {privacyPolicy?.id !== undefined && (
        <section className="ser_v_araeea green_bg manages_heis manages_top" id="about_pgs">
          <img src="/images/flo_partss.png" className="flo_partss" alt="" />
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="al_sprts_pages">
                  <h3>{privacyPolicy.name}</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cnt_datas">{privacyPolicy.sub_title}</div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="ser_v_araeea pt-0" id="page_contents">
        <div className="container">
          <div className="discriptionss">
            <div className="un_cntxtx">
              <h4>Privacy Policy</h4>
              {privacyPolicy?.description && (
                <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: privacyPolicy.description,
                  }}
                ></div>
				</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
