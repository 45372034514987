import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import PhoneInput from "react-country-phone-input";
import { ClipLoader } from "react-spinners";

const Careers = () => {
  const { id } = useParams();
  const [bannerData, setBannerData] = useState({});
  const [singleData, setSingleData] = useState({});
  const [image, setImage] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [buttonloader, setButtonloader] = useState(false);
  const [jobData, setJobData] = useState({
    firstName: "",
    fullPhone: "",
    lastName: "",
    email: "",
    phone: "",
    country_code: "",
    gender: "",
    location: "",
    resume: null,
  });
  const [errors, setErrors] = useState({});

  const handlePhoneChange = (value, country) => {
    setErrors({ ...errors, fullPhone: "" });
    setJobData({
      ...jobData,
      fullPhone: value,
      phone: value.slice(country.dialCode.length),
      country_code: `+${country.dialCode}`,
    });
  };

  const getAllData = async () => {
    try {
      const bannerData = await axiosClient.get(
        "/jobs/getBannerInfo?language=en"
      );
      if (!bannerData.data.error) {
        setBannerData(bannerData.data.data);
      }
      const data = await axiosClient.get(
        `/jobs/getJobDetails/${id}?language=en`
      );
      if (!data.data.error) {
        setSingleData(data.data.data);
      }
      const country = await axiosClient.get("/country");
      if (!country.data.error) {
        setCountryData(country.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
    setJobData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!jobData.firstName) {
      newErrors.firstName = "First name is required.";
    } else if (jobData.firstName.length > 25) {
      newErrors.firstName = "First name cannot exceed 25 characters";
    }
    if (!jobData.lastName) {
      newErrors.lastName = "Last name is required.";
    } else if (jobData.lastName.length > 25) {
      newErrors.firstName = "Last name cannot exceed 25 characters";
    }
    if (!jobData.email || !/\S+@\S+\.\S+/.test(jobData.email))
      newErrors.email = "Valid email is required.";
    if (!jobData.fullPhone || !/^\d{10}$/.test(jobData.phone))
      newErrors.fullPhone = "Phone number must be 10 digits.";
    if (!jobData.gender) newErrors.gender = "Gender is required.";
    if (!jobData.location) newErrors.location = "Location is required.";
    if (!jobData.resume) {
      newErrors.resume = "Resume is required.";
    }
    setButtonloader(false);
    // if (!jobData.resume) newErrors.resume = "Resume is required.";
    return newErrors;
  };

  const handleChange1 = async (e) => {
    try {
      setErrors({ ...errors, resume: "" });
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("all_docs", file);
      const responce = await axiosClient.post(
        "/users/uploadpdfImageDocExcelCSV",
        formData
      );
      if (!responce.data.error) {
        setJobData({ ...jobData, resume: responce.data.file });
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Image is too large",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setButtonloader(true);
      const newErrors = validate();
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setErrors({});
        const response = await axiosClient.post(`/jobs/postApplication`, {
          email: jobData.email,
          first_name: jobData.firstName,
          last_name: jobData.lastName,
          phone: jobData.phone,
          country_code: jobData.country_code,
          gender: jobData.gender,
          resume_doc: jobData.resume,
          location: jobData.location,
          job_id: +id,
        });

        if (!response.data.error) {
          setButtonloader(false);
          Swal.fire({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          setJobData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            country_code: "+91",
            fullPhone: "",
            gender: "",
            location: "",
            resume: null,
          });
          setImage(null);
          setErrors({});
          const fileInput = document.querySelector('input[name="resume"]');
          if (fileInput) {
            fileInput.value = "";
          }
        }
      }
    } catch (error) {
      setButtonloader(false);
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Application Failed",
        text:
          error.response?.data?.message ||
          "An error occurred during application. Please try again.",
      });
    }
  };

  return (
    <>
      <section
        className="ser_v_araeea green_bg manages_heis manages_top"
        id="about_pgs"
      >
        <img src="/images/flo_partss.png" className="flo_partss" alt="" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="al_sprts_pages">
                {bannerData.name && <h3>{bannerData.name}</h3>}
              </div>
            </div>
            <div className="col-lg-6 mb_view_hide">
              {bannerData.sub_title && (
                <div className="cnt_datas">{bannerData.sub_title}</div>
              )}
            </div>
          </div>
        </div>
      </section>

      {singleData.id && (
        <section className="ser_v_araeea" id="page_contents">
          <div className="container">
            <div className="discriptionss mt-0">
              <div className="un_cntxtx">
                <h3>{singleData.title_ch}</h3>
                <span>{singleData.company_address_ch}</span>
                <p>{singleData.description_ch}</p>
              </div>

              <div className="un_cntxtx">
                <h4>Qualifications</h4>
                <p>{singleData.qualification_ch}</p>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="ser_v_araeea evnetsts pt-2">
        <div className="container">
          <div className="al_sec_ctxt text-center">
            <h2>Fill this form to move ahead!</h2>
          </div>

          <div className="form_boxx">
            <div className="form_arar mt-0">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        placeholder="Enter first name"
                        value={jobData.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName && (
                        <span className="text-danger">{errors.firstName}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        placeholder="Enter last name"
                        value={jobData.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName && (
                        <span className="text-danger">{errors.lastName}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="Enter email address"
                        value={jobData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="">
                      <label>Mobile No.</label>
                      <PhoneInput
                        className={`react-tel-input ${
                          errors.fullPhone ? "input-error" : ""
                        }`}
                        country={"in"} // Default to 'in' or use stored country
                        value={jobData.fullPhone || ""}
                        onChange={(value, country) =>
                          handlePhoneChange(value, country)
                        }
                        placeholder="Enter Phone Number"
                        inputProps={{
                          name: "phoneNumber",
                          required: true,
                          autoFocus: true,
                        }}
                      />
                      {errors.fullPhone && (
                        <div className="error">{errors.fullPhone}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Gender</label>
                      <select
                        className="form-control"
                        name="gender"
                        value={jobData.gender}
                        onChange={handleChange}
                      >
                        <option value="">Choose your gender</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        {/* <option value="3">Other</option> */}
                      </select>
                      {errors.gender && (
                        <span className="text-danger">{errors.gender}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Location</label>
                      <select
                        className="form-control"
                        name="location"
                        value={jobData.location}
                        onChange={handleChange}
                      >
                        <option value="">Choose your location</option>

                        {countryData.map((res, i) => (
                          <option key={i} value={res.id}>
                            {res.name}
                          </option>
                        ))}
                      </select>
                      {errors.location && (
                        <span className="text-danger">{errors.location}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group" id="browsers">
                      <label>Resume</label>
                      <input
                        type="file"
                        className="form-control"
                        name="resume"
                        onChange={handleChange1}
                      />
                      {errors.resume && (
                        <span className="text-danger">{errors.resume}</span>
                      )}
                      <span className="smtxtx">
                        Attach your resume or paste your drive link here
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group mt-3">
                      <button
                        disabled={buttonloader}
                        type="submit"
                        className="sub_mitess"
                      >
                        {buttonloader ? (
                          <ClipLoader color="#fff" size={"25"} />
                        ) : (
                          "Apply"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
