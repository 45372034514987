import { Link, useNavigate } from "react-router-dom";
import { React, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import ProgressBar from "@ramonak/react-progress-bar";
import axiosClient from "../../Services/Axios/axios";
import HtmlWithLimit from "../../Components/Common/HtmlWithLimit";
import { BeatLoader, ClipLoader } from "react-spinners";

const MedicalCause = {
  margin: 0,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const EmergencyDonation = {
  margin: 0,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const Donate = () => {
  const [bannerData, setBannerData] = useState({});
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);
  const [categoryViseData, setCategoryViseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [categoryCount, setCategoryCount] = useState(0);
  const [buttonloader, setButtonloader] = useState(false);
  const [extraInfoData, setExtraInfoData] = useState({});

  const getAllData = async () => {
    try {
      setLoading(true);
      const category = await axiosClient.get(
        `/campaigns/getCampaignsCategory?language=en`
      );
      if (!category.data.error) {
        setBannerData(category.data.BannerContent);
        setCategoryData(category.data.data);
      }
      const topInfo = await axiosClient.get(`web/getAboutUsInfo?language=en`);
      if (!topInfo.data.error) {
        setExtraInfoData(topInfo.data.ExtraInfo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getCategroyWiseData = async () => {
    try {
      setButtonloader(true);
      const categoryViseData = await axiosClient.get(
        `/campaigns/getCategoryWiseCampaign?language=en&pageSize=${limit}&pageNo=${page}`
      );
      if (!categoryViseData.data.error) {
        setCategoryViseData(categoryViseData.data.data.rows);
        setCategoryCount(categoryViseData.data.data.totalElement);
        setButtonloader(false);
      }
    } catch (error) {
      setButtonloader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    getCategroyWiseData();
  }, [limit, page]);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      <section className="ser_v_araeea green_bg datates_pgs manages_top" id="about_pgs">
        <img src="/images/flo_partss.png" className="flo_partss" alt="" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="al_sprts_pages">
                {bannerData?.name && <h3>{bannerData.name}</h3>}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="choose_categorrys mb_view_show">
                <ul>
                  {categoryData.map((res) => {
                    return (
                      <li>
                        <Link to={`/donate-category/${res.page_url}`}>
                          {res.category_name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {bannerData.sub_title && (
                <div className="cnt_datas mb_view_hide">
                  {bannerData.sub_title}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="box_ara_partss mb_view_hide">
          <div className="container">
            <div className="row">
              {categoryData.map((res) => (
                <div className="col-lg-2" key={res.id}>
                  <div
                    className="usr_lgo_parts"
                    onClick={() => navigate(`/donate-category/${res.page_url}`)}
                  >
                    <img
                      className="categoryImage"
                      src={res.category_image}
                      alt=""
                    />
                    <p>{res.category_name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/*
      <section className="ser_v_araeea pb-0">
        <div className="container">
          <div className="filtter_area_byss">
            <div className="row">
              <div className="col-lg-6">
                <div className="tittls_name">
                  <h4>Categories</h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="slt_are_dropss">
                  <div className="slt_opt_partss">
                    <h6>Sort By</h6>
                    <select>
                      <option value="">Newest</option>
                    </select>
                  </div>

                  <div className="slt_opt_partss">
                    <h6>Categories</h6>
                    <select>
                      <option value="">Select Categories</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
	  */}

      {categoryViseData.map((res) =>
        res.campaigns.length !== 0 ? (
          <section className="ser_v_araeea pb-0" id="charity_campaign">
            <div className="container">
              <div className="al_sec_ctxt">
                <h2>
                  {res.dataValues.category_name}{" "}
                  {res.campaigns.length >= 3 && (
                    <span className="user_lists">
                      <Link to={`/donate-category/${res.dataValues.page_url}`}>
                        See more
                      </Link>
                    </span>
                  )}
                </h2>
              </div>
              <div className="row">
                {res.campaigns.map((val) => {
                  return (
                    <div className="col-lg-4 mb_view_hide">
                      <Link to={`/donate-details/${val.page_url}`}>
                        <div className="cm_box_listst">
                          <div className="cm_mg_box">
                            <img
                              src={val.donation_images}
                              className="h-100"
                              alt=""
                            />
                          </div>
                          <div className="cnt_al_boxx">
                            <span className="sm_txt">
                              {val.user.name}{" "}
                              <img src="/images/gr_tik_sm.png" alt="" />
                            </span>
                            <h3>{val.donation_title}</h3>
                            <p>
                              <HtmlWithLimit
                                htmlString={val.donation_short_description}
                                limit={500}
                              />
                            </p>
                            <div className="skills">
                              <ProgressBar
                                isLabelVisible={false}
                                completed={(
                                  (val.till_donation_amount /
                                    val.global_amount) *
                                  100
                                ).toFixed(2)}
                                bgColor={"#097C81"}
                                height={"12px"}
                                labelSize={"12px"}
                                transitionDuration={"0.3s"}
                              />
                            </div>
                            <h6>
                              Raised {val.till_donation_amount} USD{" "}
                              <span className="sm_t">
                                of {val.global_amount} USD
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}

                {/* ---------- Mobile View ---------- */}
                <div className="col-lg-12 CharityCampaign mb_view_show">
                  <OwlCarousel
                    className="slider-items owl-carousel dots_allss"
                    id="three_points"
                    {...MedicalCause}
                  >
                    {res.campaigns.map((val) => (
                      <div className="item">
                        <Link to={`/donate-details/${val.page_url}`}>
                          <div className="cm_box_listst">
                            <div className="cm_mg_box">
                              <img src={val.donation_images} alt="" />
                            </div>
                            <div className="cnt_al_boxx">
                              <span className="sm_txt">
                                {val.user.name}{" "}
                                <img src="/images/gr_tik_sm.png" alt="" />
                              </span>
                              <h3>{val.donation_title}</h3>
                              <div className="dis_contenttss">
                                <HtmlWithLimit
                                  htmlString={val.donation_short_description}
                                  limit={500}
                                />
                              </div>
                              <div className="skills">
                                <ProgressBar
                                  isLabelVisible={false}
                                  completed={(
                                    (val.till_donation_amount /
                                      val.global_amount) *
                                    100
                                  ).toFixed(2)}
                                  bgColor={"#097C81"}
                                  height={"12px"}
                                  labelSize={"12px"}
                                  transitionDuration={"0.3s"}
                                />
                              </div>
                              <h6>
                                Raised {val.till_donation_amount} USD{" "}
                                <span className="sm_t">
                                  of {val.global_amount} USD
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
                {/* ---------- End Mobile View ---------- */}
              </div>
            </div>
          </section>
        ) : null
      )}

      {categoryViseData && categoryCount >= limit && (
        <section className="ser_v_araeea pt-3">
          <div className="container">
            <div className="vei_als">
              <Link to="#" onClick={() => setLimit(limit + 10)}>
                <span className="managess_bnt">
                  {buttonloader ? (
                    <ClipLoader color="#fff" size={"25"} />
                  ) : (
                    <>
                      {" "}
                      View All Categories{" "}
                      <img src="/images/chevron-right.png" alt="" />
                    </>
                  )}
                </span>
              </Link>
            </div>
          </div>
        </section>
      )}
      <div id="Footer_arae">
        <div className="ftr_manages">
          <div className="th_parts_bxx">
            <div className="container">
              <div class="al_sec_ctxt text-center wit_tx">
                <h2>{extraInfoData?.name}</h2>
              </div>
              {extraInfoData?.description && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: extraInfoData?.description,
                  }}
                ></p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donate;
