import React, { useState } from "react";
import LogoIcon from "../../assets/Images/MiniLogo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";

const SetPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(
      passwords.confirmPassword
    );
    const hasUpperCase = /[A-Z]/.test(passwords.confirmPassword);
    const hasLowerCase = /[a-z]/.test(passwords.confirmPassword);
    const hasNumber = /\d/.test(passwords.confirmPassword);
    const isValidLength = passwords.confirmPassword.length >= 8;

    if (
      !hasSpecialChar ||
      !hasUpperCase ||
      !hasLowerCase ||
      !hasNumber ||
      !isValidLength
    ) {
      Swal.fire({
        icon: "error",
        title: "Invalid Password",
        text: "Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
      });
      return;
    }
    if (passwords.newPassword !== passwords.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Passwords do not match",
        text: "Please ensure both passwords are the same.",
      });
      return;
    }

    try {
      const response = await axiosClient.post("/users/reset-password", {
        str: id,
        password: passwords.newPassword,
      });

      if (!response.data.error) {
        Swal.fire({
          icon: "success",
          title: "Password Reset Successful",
          text: "Your password has been successfully reset.",
        }).then(() => {
          navigate("/login");
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  return (
    <>
      <div className="container-fluid vh-100 d-flex justify-content-center login-container manages_top">
        <div className="row w-100 h-100">
          <div className="col-md-6 d-none d-md-block"></div>
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center h-100">
            <div className="card p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center sc_lgo_allss">
              <div className="d-flex justify-content-center">
                <img src={LogoIcon} alt="WorldTrust" />
              </div>
              <h3 className="text-center">Set a New Password</h3>
              <form className="w-50" onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label>New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter new password"
                    name="newPassword"
                    value={passwords.newPassword}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm new password"
                    name="confirmPassword"
                    value={passwords.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="d-flex flex-column align-items-center w-100">
                  <button type="submit" className="btn bg-natural w-100">
                    Reset Password
                  </button>
                  <p className="text-start mt-3">
                    By clicking the button, you agree to the WorldTrust Terms of
                    Service and acknowledge the Privacy Notice.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetPassword;
