import React, { useEffect, useState } from "react";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";

const ImageUploader = ({ setState, defaultImage }) => {
  const [image, setImage] = useState(null);
  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      const responce = await axiosClient.post("/users/uploadimg", formData);
      if (!responce.data.error) {
        setState(responce.data.file);
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Image is too large",
      });
    }
  };

  useEffect(() => {
    if (defaultImage) {
      setImage(defaultImage);
    }
  }, [defaultImage]);
  return (
    <div id="imgss_upload">
      <div className="nid-upload-container">
        <div className="nid-image-preview">
          {image ? (
            <a href={image} target="_blank" rel="noopener noreferrer">
              <img src={image} alt="NID Preview" className="image-preview" />
            </a>
          ) : (
            <div className="placeholder"></div>
          )}
        </div>
        <label className="upload-button">
          Upload Image
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </label>
      </div>
    </div>
  );
};

export default ImageUploader;
