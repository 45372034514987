import React from "react";
import { Modal } from "react-bootstrap";

const FilterModal = ({ show, handleClose }) => {
  return (
    <>
      {" "}
      <Modal className="all_supportss sm_sizess" id="registeress" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="fromss_boxx">
            <div className="filtterr_boxxss">
              <div className="lst_araes">
			   <label>Date</label>
			   <input type="text" name="" class="tx_input_area" placeholder="Select Date" />
			  </div>
			  
			  <div className="lst_araes marge">
			   <label>Location</label>
			   <input type="text" name="" class="tx_input_area" placeholder="Search Location" />
			   <img src="/images/search_g.png" alt="" />
			  </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FilterModal;
