import { Link } from "react-router-dom";
import { React, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import ReactReadMoreReadLess from "react-read-more-read-less";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";

const aboutmgslidss = {
  margin: 0,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const AboutUs = () => {
  const [topInformation, setTopInformation] = useState({});
  const [extraInfoData, setExtraInfoData] = useState({});
  const [mission, setMission] = useState({});
  const [vision, setVision] = useState({});
  const [howWork, setHowWork] = useState({});
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState([]);
  const getAllData = async () => {
    try {
      setLoading(true);
      const topInfo = await axiosClient.get(`web/getAboutUsInfo?language=en`);
      if (!topInfo.data.error) {
        setTopInformation(topInfo.data.data);
        setExtraInfoData(topInfo.data.ExtraInfo);
      }

      const mission = await axiosClient.get(
        `web/getOurMissionInfo?language=en`
      );
      if (!mission.data.error) {
        setMission(mission.data.data);
      }

      const vision = await axiosClient.get(`web/getOurVisionInfo?language=en`);
      if (!vision.data.error) {
        setVision(vision.data.data);
      }

      const howWork = await axiosClient.get(`web/getHowWeAreInfo?language=en`);
      if (!howWork.data.error) {
        setHowWork(howWork.data.data);
      }

      const team = await axiosClient.get(`web/getTeams?language=en`);
      if (!team.data.error) {
        setTeam(team.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }

  return (
    <>
      {topInformation.id != undefined && (
        <section className="ser_v_araeea green_bg manages_top" id="about_pgs">
          <img src="/images/flo_partss.png" className="flo_partss" alt="" />
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="al_sprts_pages">
                  <p>{topInformation.name}</p>
                  <h3>{topInformation.sub_title}</h3>
                </div>
              </div>
              <div className="col-lg-6 mb_view_hide">
                <div className="cnt_datas">{topInformation.description}</div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* ---------- Mobile View ---------- */}
      {topInformation.id != undefined && (
        <div className="container">
          <div
            className="CharityCampaign mb_view_show abt_mgeess_bx"
            id="about_pgsssess"
          >
            <OwlCarousel
              className="slider-items owl-carousel dots_allss"
              id="three_points"
              {...aboutmgslidss}
            >
              {topInformation.image && (
                <div className="item">
                  <div className="mg_ab_area">
                    <img src={topInformation.image} alt="" />
                  </div>
                </div>
              )}

              {topInformation.image2 && (
                <div className="item">
                  <div className="mg_ab_area">
                    <img src={topInformation.image2} alt="" />
                  </div>
                </div>
              )}

              {topInformation.image3 && (
                <div className="item">
                  <div className="mg_ab_area">
                    <img src={topInformation.image3} alt="" />
                  </div>
                </div>
              )}
            </OwlCarousel>
          </div>
        </div>
      )}
      {/* ---------- End Mobile View ---------- */}
      {topInformation.id != undefined && (
        <section className="ser_v_araeea abt_mgeess_bx mb_view_hide">
          <div className="container">
            <div className="row">
              {topInformation.image && (
                <div className="col-lg-4">
                  <div className="mg_ab_area">
                    <img src={topInformation.image} alt="" />
                  </div>
                </div>
              )}
              {topInformation.image2 && (
                <div className="col-lg-4">
                  <div className="mg_ab_area">
                    <img src={topInformation.image2} alt="" />
                  </div>
                </div>
              )}
              {topInformation.image3 && (
                <div className="col-lg-4">
                  <div className="mg_ab_area">
                    <img src={topInformation.image3} alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      )}

      {mission && (
        <section className="ser_v_araeea pt-0 abt_cont_hm">
          <div className="container">
            <div className="mis_contents">
              <div className="content_txs">
                <h4>Our Mission</h4>
                <p className="mb_view_hide">
                  WorldTrust.io is dedicated to bridging the gap between passion
                  and impact by unifying NGOs and donors on a transparent,
                  secure, and innovative platform. Our mission unfolds in
                  phases: Phase One: To uplift third-world countries by
                  providing access to essential resources, education, and
                  infrastructure. We aim to empower local communities by
                  supporting impactful projects that promote self-sufficiency
                  and sustainable development. Phase Two: To foster economic
                  growth by facilitating partnerships between NGOs, businesses,
                  and government organizations, ensuring that resources are
                  allocated effectively and responsibly for long-term viability.
                  Phase Three:To create a global network that shares successful
                  strategies and innovations, enabling countries to learn from
                  one another in addressing their unique challenges and
                  aspirations for development. Through this phased approach, we
                  aim to transform societies, improve living conditions, and
                  promote opportunities for future generations in every corner
                  of the globe.{" "}
                </p>
                {/* ---------- Mobile View ---------- */}
                <p className="mb_view_show">
                  <ReactReadMoreReadLess
                    charLimit={350}
                    readMoreText={"View More"}
                    readLessText={"View less"}
                  >
                    {
                      "WorldTrust.io is dedicated to bridging the gap between passion and impact by unifying NGOs and donors on a transparent, secure, and innovative platform. Our mission unfolds in phases: Phase One: To uplift third-world countries by providing access to essential resources, education, and infrastructure. We aim to empower local communities by supporting impactful projects that promote self-sufficiency and sustainable development. Phase Two: To foster economic growth by facilitating partnerships between NGOs, businesses, and government organizations, ensuring that resources are allocated effectively and responsibly for long-term viability. Phase Three:To create a global network that shares successful strategies and innovations, enabling countries to learn from one another in addressing their unique challenges and aspirations for development. Through this phased	approach, we aim to transform societies, improve living conditions, and promote opportunities for future generations in every corner of the globe."
                    }
                  </ReactReadMoreReadLess>
                </p>
                {/* ---------- End Mobile View ---------- */}
              </div>
              <div className="content_txs">
                <h4>Our Vision</h4>
                <p>
                  "To create a world where every charitable act is empowered
                  through unity, transparency, and trust, transforming lives and
                  communities globally."
                </p>
              </div>
            </div>
          </div>
        </section>
      )}

      {howWork && (
        <section className="ser_v_araeea greas_bg" id="how_we_w_hm">
          <div className="container">
            <div class="al_sec_ctxt">
              <h2>How we work</h2>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <div className="usr_ctnxt">
                  <p>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                    aut natus minima eum obcaecati voluptas est aliquam soluta.
                  </p>
                  {/* ---------- Mobile View ---------- */}
                  <div className="how_usr_imgs mb_view_show mt-3">
                    <img src="/images/who_abt.png" alt="" />
                  </div>
                  {/* ---------- Mobile View ---------- */}

                  <div className="usr_cotet_list">
                    <span className="count_numbbr">01</span>
                    <h5>
                      Lorem ipsum dolor sit amet. Sed consequatur necessita
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                      aut natus minima eum obcaecati voluptas est aliquam
                      soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed
                      consequatur necessitatibus aut natus min
                    </p>
                  </div>

                  <div className="usr_cotet_list">
                    <span className="count_numbbr">02</span>
                    <h5>
                      Lorem ipsum dolor sit amet. Sed consequatur necessita
                      Lorem ipsum
                    </h5>
                  </div>

                  <div className="usr_cotet_list">
                    <span className="count_numbbr">03</span>
                    <h5>
                      Lorem ipsum dolor sit amet. Sed consequatur necessita
                      Lorem ipsum
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 mb_view_hide">
                <div className="how_usr_imgs">
                  <img src="/images/who_abt.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {team && (
        <section className="ser_v_araeea" id="we_teams_hm">
          <div className="container">
            <div class="al_sec_ctxt text-center">
              <h2>We’ve got an Exceptional team</h2>
            </div>
            <div className="row justy_centr">
              {team.map((item, index) => (
                <div className="col-lg-3 col-6">
                  <div className="usr_tm_prof">
                    <div className="us_imgss">
                      <img src={item.image} alt="" />
                    </div>
                    <h6>{item.name}</h6>
                    <p>{item.designation}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      <div id="Footer_arae">
        <div className="ftr_manages">
          <div className="th_parts_bxx">
            <div className="container">
              <div class="al_sec_ctxt text-center wit_tx">
                <h2>{extraInfoData?.name}</h2>
              </div>
              {extraInfoData?.description && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: extraInfoData?.description,
                  }}
                ></p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
