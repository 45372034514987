import { Link } from "react-router-dom";
import { React, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import CountUp from "react-countup";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { Modal, Button } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import "animate.css";
import ProfileSidebar from "./common/ProfileSidebar";
import CommanPagination from "./common/CommanPagination/CommanPagination";

const Login = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const videoshow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [gratitudeData, setGratitudeData] = useState();

  return (
    <>
      <Modal
        className="all_supportss sm_sizess"
        id="DonationSuccessful"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="fromss_boxx tr_histry">
            <div className="cntr_box_all">
              <div className="form_arar sussess">
                <div class="sus_lists">
                  <img src="/images/suss_tik.png" alt="" />
                  <h2>Donation Successful</h2>
                  <p>
                    Thank you for donating with Worldtrust. Please check your
                    transaction details below
                  </p>
                </div>

                <div className="brd_liness mt-4 mb-4">&nbsp;</div>

                <div className="top_user">
                  <div className="usr_pickss">
                    <img src="/images/donate_dt_sm1.png" alt="" />
                  </div>
                  <div className="cnt_tx_cntss">
                    Every Heartbeat Matters: Saving Aiden's Heart [Heart
                    Menders]
                  </div>
                </div>

                <div className="brd_liness mt-4 mb-4">&nbsp;</div>

                <div class="form-group manags_cntxt black_tx">
                  Transactions Details
                </div>
                <div class="form-group manags_cntxt">
                  Transaction ID <span class="pricde">UTG867IUT8855677</span>
                </div>

                <div className="brd_liness mt-2 mb-4">&nbsp;</div>

                <div className="pri_liststs">
                  <div class="form-group manags_cntxt">
                    Bank Name <span class="pricde black_tx">City Bank</span>
                  </div>
                  <div class="form-group manags_cntxt">
                    Account No.{" "}
                    <span class="pricde black_tx">8675876847548432</span>
                  </div>
                  <div class="form-group manags_cntxt">
                    IFSC Code <span class="pricde black_tx">IUT89HKJ5</span>
                  </div>
                  <div class="form-group manags_cntxt">
                    Amount <span class="pricde black_tx">200.00 USD</span>
                  </div>
                </div>

                <div className="brd_liness mt-2 mb-4">&nbsp;</div>

                <div className="gpr_g_textss">
                  <img src="/images/g_flor.png" alt="" />
                  <div className="grp_txtx">
                    <h6>
                      <span className="b_text">20</span>{" "}
                      <span className="sm_text">Merit Points</span> Credited
                    </h6>
                  </div>
                </div>

                <div className="price_list link_btnss">
                  <Link to="" className="act">
                    Go to Dashboard
                  </Link>
                </div>

                <div className="mang_und_listst">
                  <div className="form-group">
                    <p className="by_textxt">
                      You will receive a confirmation email once we have
                      verified the donation details. If you have any enquiries
                      please email customer support at
                      <Link to="mailto:support@worldtrust.io">
                        support@worldtrust.io
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea evnetsts profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" /> Gratitude
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                {/* <div className="my_profiless">
                  <div className="top_pro_ct_mg">
                    <div className="pro_imgs">
                      <img src="/images/my_profl.png" alt="" />
                    </div>
                    <h4>Jackson(MU5785)</h4>
                    <p>jacksoncomp@gmail.com</p>
                  </div>

                  <ul className="profile_menus">
                    <li>
                      <Link to="/impact">
                        <img src="/images/side_m_ic1.png" alt="" /> Impact
                      </Link>
                    </li>
                    <li>
                      <Link to="/Profile">
                        <img src="/images/side_m_ic2.png" alt="" /> Profile
                      </Link>
                    </li>
                    <li>
                      <Link to="/kyc">
                        <img src="/images/side_m_ic10.png" alt="" /> KYC
                      </Link>
                    </li>
                    <li>
                      <Link to="/tax-information">
                        <img src="/images/side_m_ic11.png" alt="" /> Tax
                        Information
                      </Link>
                    </li>
                    <li>
                      <Link to="/Gratitude">
                        <img src="/images/side_m_ic3.png" alt="" /> Gratitude
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-events">
                        <img src="/images/side_m_ic4.png" alt="" /> Events
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img src="/images/side_m_ic5.png" alt="" /> Campaigns
                      </Link>
                    </li>
                    <li>
                      <Link to="/transaction-history" className="act">
                        <img src="/images/side_m_ic6.png" alt="" /> Transactions
                      </Link>
                    </li>
                    <li>
                      <Link to="/referral">
                        <img src="/images/side_m_ic7.png" alt="" /> Referral
                      </Link>
                    </li>
                    <li>
                      <Link to="/settings">
                        <img src="/images/side_m_ic8.png" alt="" /> Settings
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img src="/images/side_m_ic9.png" alt="" /> Logout
                      </Link>
                    </li>
                  </ul>

                  <div className="merit_point">
                    <div className="pnt_logos">
                      <img src="/images/point_logo.png" alt="" />
                    </div>
                    <div className="cnt_textxt">
                      <h2>400</h2>
                      <p>Merit Points</p>
                    </div>
                  </div>
                </div> */}
                <ProfileSidebar/>
              </div>

              <div className="col-lg-9">
                <div
                  className="entnts_tabss profff_eventss profilss"
                  id="forths_managess"
                >
                  <h4 className="br_space">Transaction List</h4>
                  <Tabs>
                    <div className="tabs_list prof_eventss" id="al_tbs_neww">
                      <div className="tbdsa">
                        <TabList>
                          <Tab>All(19)</Tab>
                          <Tab>Completed</Tab>
                          <Tab>Inprocess</Tab>
                          <Tab>Pending</Tab>
                        </TabList>
                      </div>
                    </div>

                    <div className="content-wrapper">
                      <TabPanel>
                        <div class="table-responsive mt-2">
                          <table class="table table-striped al_suport_items mb-0">
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Event / Campaign</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Merits</th>
                                <th>Rating</th>
                                <th>Status</th>
                                <th className="last">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <Button variant="primary" onClick={videoshow}>
                                    <img src="/images/eye_ico.png" alt="" />
                                  </Button>
                                </td>
                              </tr>

                              <tr>
                                <td>2</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="goldan_tx_w">Inprocess</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye-crossed.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>3</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="red_tx_w">Pending</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye-crossed.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>4</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>5</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>6</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>7</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>8</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="pages_listst">
                          <div className="row mt-4">
                            <div className="col-lg-6">
                              <div className="list_cnts">
                                Showing 1 to 9 of 5 entries
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="paginationss">
                                <ul>
                                  <li>
                                    <Link to="">Previous</Link>
                                  </li>
                                  <li>
                                    <Link to="" className="act">
                                      1
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="">2</Link>
                                  </li>
                                  <li>
                                    <Link to="">3</Link>
                                  </li>
                                  <li>
                                    <Link to="">4</Link>
                                  </li>
                                  <li>
                                    <Link to="">Next </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <CommanPagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          limit={limit}
                          setLimit={setLimit}
                          totalCounts={gratitudeData?.data?.totalElement}
                        />

                      </TabPanel>
                      <TabPanel>
                        <div class="table-responsive mt-2">
                          <table class="table table-striped al_suport_items mb-0">
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Event / Campaign</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Merits</th>
                                <th>Rating</th>
                                <th>Status</th>
                                <th className="last">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>2</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>3</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>4</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>5</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="green_tx_w">Completed</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="pages_listst">
                          <div className="row mt-4">
                            <div className="col-lg-6">
                              <div className="list_cnts">
                                Showing 1 to 9 of 5 entries
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="paginationss">
                                <ul>
                                  <li>
                                    <Link to="">Previous</Link>
                                  </li>
                                  <li>
                                    <Link to="" className="act">
                                      1
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="">2</Link>
                                  </li>
                                  <li>
                                    <Link to="">3</Link>
                                  </li>
                                  <li>
                                    <Link to="">4</Link>
                                  </li>
                                  <li>
                                    <Link to="">Next </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <CommanPagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          limit={limit}
                          setLimit={setLimit}
                          totalCounts={gratitudeData?.data?.totalElement}
                        />
                      </TabPanel>

                      <TabPanel>
                        <div class="table-responsive mt-2">
                          <table class="table table-striped al_suport_items mb-0">
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Event / Campaign</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Merits</th>
                                <th>Rating</th>
                                <th>Status</th>
                                <th className="last">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="goldan_tx_w">Inprocess</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>2</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="goldan_tx_w">Inprocess</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>3</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="goldan_tx_w">Inprocess</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="pages_listst">
                          <div className="row mt-4">
                            <div className="col-lg-6">
                              <div className="list_cnts">
                                Showing 1 to 9 of 5 entries
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="paginationss">
                                <ul>
                                  <li>
                                    <Link to="">Previous</Link>
                                  </li>
                                  <li>
                                    <Link to="" className="act">
                                      1
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="">2</Link>
                                  </li>
                                  <li>
                                    <Link to="">3</Link>
                                  </li>
                                  <li>
                                    <Link to="">4</Link>
                                  </li>
                                  <li>
                                    <Link to="">Next </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <CommanPagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          limit={limit}
                          setLimit={setLimit}
                          totalCounts={gratitudeData?.data?.totalElement}
                        />
                      </TabPanel>

                      <TabPanel>
                        <div class="table-responsive mt-2">
                          <table class="table table-striped al_suport_items mb-0">
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Event / Campaign</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Merits</th>
                                <th>Rating</th>
                                <th>Status</th>
                                <th className="last">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="red_tx_w">Pending</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>2</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="red_tx_w">Pending</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>

                              <tr>
                                <td>3</td>
                                <td>fund collection for flood survivals</td>
                                <td>Aug, 28 2024 02:28:22 PM </td>
                                <td>200.00 USD</td>
                                <td className="green_tx_w">5</td>
                                <td className="goldan_tx_w flexx">
                                  5 <img src="/images/star_gld.png" alt="" />
                                </td>
                                <td className="red_tx_w">Pending</td>
                                <td className="green_tx_w">
                                  <img src="/images/eye_ico.png" alt="" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="pages_listst">
                          <div className="row mt-4">
                            <div className="col-lg-6">
                              <div className="list_cnts">
                                Showing 1 to 9 of 5 entries
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="paginationss">
                                <ul>
                                  <li>
                                    <Link to="">Previous</Link>
                                  </li>
                                  <li>
                                    <Link to="" className="act">
                                      1
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="">2</Link>
                                  </li>
                                  <li>
                                    <Link to="">3</Link>
                                  </li>
                                  <li>
                                    <Link to="">4</Link>
                                  </li>
                                  <li>
                                    <Link to="">Next </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <CommanPagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          limit={limit}
                          setLimit={setLimit}
                          totalCounts={gratitudeData?.data?.totalElement}
                        />
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
