import React, { useEffect, useState } from "react";
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import { Modal } from "react-bootstrap";

const ShareModal = ({ isModalOpen, handleClose1, ShareText }) => {
  const [shareUrl, setShareUrl] = useState(window.location.href);
  const [shareText, setShareLine] = useState(ShareText);

  return (
    <>
      {" "}
      <Modal className="all_supportss sm_sizess" id="capture" show={isModalOpen} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header> 
        <Modal.Body>
          <div className="sher_mdl_area">
            <h1>Share this on Social Media</h1>
            <div className="ic_icon_area">
              <WhatsappShareButton url={shareUrl} title={shareText}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>

              <FacebookShareButton url={shareUrl} quote={shareText}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <TwitterShareButton url={shareUrl} title={shareText}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
