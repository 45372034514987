import React, { useEffect, useState } from "react";
import ProfileSidebar from "./common/ProfileSidebar";
import { Link } from "react-router-dom";
import ImageUploader from "./common/ImageUploader";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import CommanImagePDf from "./common/CommanImagePDf";

const AdditionalInformation = () => {
  const [userExtraInfo, setUserExtraInfo] = useState({
    country_id: "",
    ngo_rgistration_number: "",
    tin_number: "",
    employer_identification_number: "",
    unique_entity_number: "",
    global_ngo_id: "",
    secondary_contact: "",
    website_url: "",
    year_of_establishment: "",
    ngo_certificate_of_registration: null,
    articales_association: null,
    operating_license: null,
    exemption_certificate: null,
    bank_name: "",
    account_number: "",
    swift_code: "",
    account_holder_name: "",
    proof_account: null,
    social_media: [{ name: "", value: "" }],
  });
  const [buttonloader, setButtonloader] = useState(false);

  const [errors, setErrors] = useState({});
  const [countryData, setCountryData] = useState([]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserExtraInfo((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: "" }));
  };

  const handleSocialMediaChange = (index, field, value) => {
    const updatedSocialMedia = userExtraInfo.social_media.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setUserExtraInfo((prevState) => ({
      ...prevState,
      social_media: updatedSocialMedia,
    }));
    setErrors((prevState) => {
      const updatedErrors = { ...prevState };
      updatedErrors[`social_media_${index}_${field}`] = "";
      return updatedErrors;
    });
  };

  const addSocialMediaField = () => {
    setUserExtraInfo({
      ...userExtraInfo,
      social_media: [...userExtraInfo.social_media, { name: "", value: "" }],
    });
  };

  const removeSocialMediaField = (index) => {
    const updatedSocialMedia = userExtraInfo.social_media.filter(
      (_, i) => i !== index
    );
    setUserExtraInfo({ ...userExtraInfo, social_media: updatedSocialMedia });
  };

  const validateFields = () => {
    const newErrors = {};

    // NGO Registration Number: Required
    if (!userExtraInfo.ngo_rgistration_number) {
      newErrors.ngo_rgistration_number = "NGO Registration Number is required.";
    }

    // TIN Number: Required and should be numeric
    if (!userExtraInfo.tin_number) {
      newErrors.tin_number = "TIN Number is required.";
    } else if (!/^\d+$/.test(userExtraInfo.tin_number)) {
      newErrors.tin_number = "TIN Number must be numeric.";
    }

    // Employer Identification Number: Required
    if (!userExtraInfo.employer_identification_number) {
      newErrors.employer_identification_number =
        "Employer Identification Number is required.";
    }

    // Unique Entity Number: Required
    if (!userExtraInfo.unique_entity_number) {
      newErrors.unique_entity_number = "Unique Entity Number is required.";
    }

    // Global NGO ID: Required
    if (!userExtraInfo.global_ngo_id) {
      newErrors.global_ngo_id = "Global NGO ID is required.";
    }

    // Secondary Contact: Optional but must be a valid phone number
    if (
      userExtraInfo.secondary_contact &&
      !/^\d{10}$/.test(userExtraInfo.secondary_contact)
    ) {
      newErrors.secondary_contact =
        "Secondary Contact must be a valid 10-digit number.";
    }

    // Website URL: Optional but must be a valid URL
    if (
      userExtraInfo.website_url &&
      !/^https?:\/\/[\w\-]+\.[\w\-]+/.test(userExtraInfo.website_url)
    ) {
      newErrors.website_url = "Enter a valid URL.";
    }

    // Year of Establishment: Required and must be a valid year
    if (!userExtraInfo.year_of_establishment) {
      newErrors.year_of_establishment = "Year of Establishment is required.";
    } else if (
      !/^\d{4}$/.test(userExtraInfo.year_of_establishment) ||
      parseInt(userExtraInfo.year_of_establishment, 10) >
        new Date().getFullYear()
    ) {
      newErrors.year_of_establishment =
        "Enter a valid year (e.g., 2023 or earlier).";
    }

    // NGO Certificate of Registration: Required
    if (!userExtraInfo.ngo_certificate_of_registration) {
      newErrors.ngo_certificate_of_registration =
        "NGO Certificate of Registration is required.";
    }

    // Proof of Address: Required
    if (!userExtraInfo.articales_association) {
      newErrors.articales_association = "Proof of Address is required.";
    }

    // Tax Exemption Certificate: Required
    if (!userExtraInfo.operating_license) {
      newErrors.operating_license = "Tax Exemption Certificate is required.";
    }

    // Activity or Annual Reports: Required
    if (!userExtraInfo.exemption_certificate) {
      newErrors.exemption_certificate =
        "Activity or Annual Reports are required.";
    }

    // Social Media Links: Optional but must have valid URLs
    userExtraInfo.social_media.forEach((item, index) => {
      if (item.name && !item.value) {
        newErrors[
          `social_media_${index}_value`
        ] = `URL is required for ${item.name}.`;
      } else if (
        item.value &&
        !/^https?:\/\/[\w\-]+\.[\w\-]+/.test(item.value)
      ) {
        newErrors[`social_media_${index}_value`] = "Enter a valid URL.";
      }
    });

    if (!userExtraInfo.ngo_certificate_of_registration) {
      newErrors.ngo_certificate_of_registration =
        "NGO Certificate of Registration is required.";
    }

    if (!userExtraInfo.articales_association) {
      newErrors.articales_association = "Proof of Address is required.";
    }
    if (!userExtraInfo.operating_license) {
      newErrors.operating_license = "Tax Exemption Certificate is required.";
    }
    if (!userExtraInfo.exemption_certificate) {
      newErrors.exemption_certificate =
        "Activity or Annual Reports are required.";
    }

    if (!userExtraInfo.proof_account) {
      newErrors.proof_account = "Proof of Account is required.";
    }
    if (!userExtraInfo.bank_name) {
      newErrors.bank_name = "Bank Name is required.";
    }
    if (!userExtraInfo.account_number) {
      newErrors.account_number = "Account Number is required.";
    }
    if (!userExtraInfo.swift_code) {
      newErrors.swift_code = "SWIFT Code is required.";
    }
    if (!userExtraInfo.account_holder_name) {
      newErrors.account_holder_name = "Account Holder Name is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setButtonloader(true);
      const user = JSON.parse(localStorage.getItem("userData"));
      if (validateFields()) {
        const response = await axiosClient.post(
          `/users/user-extra-information`,
          {
            ...userExtraInfo,
            user_id: user.id,
            social_media_name: userExtraInfo.social_media.map(
              (item) => item.name
            ),
            social_media_value: userExtraInfo.social_media.map(
              (item) => item.value
            ),
          }
        );
        if (!response.data.error) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
        }
        setButtonloader(false);
      } else {
        setButtonloader(false);
      }
    } catch (error) {
      console.log(error);
      setButtonloader(false);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const getCountryData = async () => {
    const response = await axiosClient.get(`/country/get-selected-country`);
    if (!response.data.error) {
      console.log(response.data.data, "response.data.data");
      setCountryData(response.data.data);
    }
  };
  const fetchUserExtraInfo = async () => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const response = await axiosClient.get(`/users/getUserExtraInformation`);
    if (response.data) {
      console.log(response.data, "response.data");
      setUserExtraInfo({
        ...userExtraInfo,
        country_id: response.data.extraData.country_id,
        ngo_rgistration_number: response.data.extraData.ngo_rgistration_number,
        tin_number: response.data.extraData.tin_number,
        employer_identification_number:
          response.data.extraData.employer_identification_number,
        unique_entity_number: response.data.extraData.unique_entity_number,
        global_ngo_id: response.data.extraData.global_ngo_id,
        secondary_contact: response.data.extraData.secondary_contact,
        website_url: response.data.extraData.website_url,
        year_of_establishment: response.data.extraData.year_of_establishment,
        ngo_certificate_of_registration:
          response.data.extraData.ngo_certificate_of_registration,
        articales_association: response.data.extraData.articales_association,
        operating_license: response.data.extraData.operating_license,
        exemption_certificate: response.data.extraData.exemption_certificate,
        bank_name: response.data.extraData.bank_name,
        account_number: response.data.extraData.account_number,
        swift_code: response.data.extraData.swift_code,
        account_holder_name: response.data.extraData.account_holder_name,
        proof_account: response.data.extraData.proof_account,
        social_media: response.data.socialData.map((item) => ({
          name: item.social_media_text,
          value: item.website_url,
        })),
      });
      // setUserExtraInfo(response.data);
    }
  };
  useEffect(() => {
    getCountryData();
    fetchUserExtraInfo();
  }, []);
  return (
    <div className="all_suport_bg manages_top">
      <section className="ser_v_araeea profiles">
        <div className="container">
          <div className="bradcams mb_view_hide">
            <span className="bothss">
              <Link to="">My Account</Link>{" "}
              <img src="/images/brd_rowwo.png" alt="" /> Additional Information
            </span>
          </div>
          <div className="row">
            <div className="col-lg-3 mb_view_hide">
              <ProfileSidebar />
            </div>

            <div className="col-lg-9">
              <div className="form_boxx profilss">
                <div className="form_arar mt-0">
                  <h4>Additional Information</h4>
                  <div className="mang_und_listst mt-0" id="two_arts_prt">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        {" "}
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Select Country</label>
                            <div className="form-group mb-1">
                              <select
                                className="form-control"
                                value={userExtraInfo.country_id} // Ensure default is an empty string
                                onChange={({ target }) =>
                                  setUserExtraInfo({
                                    ...userExtraInfo,
                                    country_id: target.value,
                                  })
                                }
                              >
                                {countryData.map((res, i) => (
                                  <option key={i} value={res.id}>
                                    {res.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>NGO Registration Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="ngo_rgistration_number"
                              placeholder="Enter NGO Registration Number"
                              value={userExtraInfo.ngo_rgistration_number}
                              onChange={handleInputChange}
                            />
                            {errors.ngo_rgistration_number && (
                              <small className="text-danger">
                                {errors.ngo_rgistration_number}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Tax Identification Number (TIN)</label>
                            <input
                              type="text"
                              className="form-control"
                              name="tin_number"
                              placeholder="Enter Tax Identification Number (TIN)"
                              value={userExtraInfo.tin_number}
                              onChange={handleInputChange}
                            />
                            {errors.tin_number && (
                              <small className="text-danger">
                                {errors.tin_number}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label> Employer Identification Number(EIN)</label>
                            <input
                              type="text"
                              className="form-control"
                              name="employer_identification_number"
                              placeholder="Enter Employer Identification Number(EIN)"
                              value={
                                userExtraInfo.employer_identification_number
                              }
                              onChange={handleInputChange}
                            />
                            {errors.employer_identification_number && (
                              <small className="text-danger">
                                {errors.employer_identification_number}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Unique Entity Number (UEN)</label>
                            <input
                              type="text"
                              className="form-control"
                              name="unique_entity_number"
                              placeholder="Enter Unique Entity Number (UEN)"
                              value={userExtraInfo.unique_entity_number}
                              onChange={handleInputChange}
                            />
                            {errors.unique_entity_number && (
                              <small className="text-danger">
                                {errors.unique_entity_number}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Global NGO ID</label>
                            <input
                              type="text"
                              className="form-control"
                              name="global_ngo_id"
                              placeholder="Enter Global NGO ID"
                              value={userExtraInfo.global_ngo_id}
                              onChange={handleInputChange}
                            />
                            {errors.global_ngo_id && (
                              <small className="text-danger">
                                {errors.global_ngo_id}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Secondary Contact</label>
                            <input
                              type="text"
                              className="form-control"
                              name="secondary_contact"
                              placeholder="Enter Secondary Contact"
                              value={userExtraInfo.secondary_contact}
                              onChange={handleInputChange}
                            />
                            {errors.secondary_contact && (
                              <small className="text-danger">
                                {errors.secondary_contact}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Website URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="website_url"
                              placeholder="Enter Website URL "
                              value={userExtraInfo.website_url}
                              onChange={handleInputChange}
                            />
                            {errors.website_url && (
                              <small className="text-danger">
                                {errors.website_url}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Year of Establishment</label>
                            <input
                              type="number"
                              className="form-control"
                              name="year_of_establishment"
                              placeholder="Enter Year of Establishment"
                              value={userExtraInfo.year_of_establishment}
                              onChange={handleInputChange}
                            />
                            {errors.year_of_establishment && (
                              <small className="text-danger">
                                {errors.year_of_establishment}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12 mb-5">
                          <div className="form-group" id="add_more_filess">
                            <div className="both_managess">
                              <label>Social Media Links</label>
                              <div className="ad_buttons">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={addSocialMediaField}
                                >
                                  + Add More
                                </button>
                              </div>
                            </div>
                            {userExtraInfo.social_media.map((item, index) => (
                              <div className="row add_mor_lisstst" key={index}>
                                <div className="col-lg-5 col-6">
                                  <div className="form-group mb-1">
                                    <select
                                      className="form-control"
                                      value={item.name || "Facebook"} // Ensure default is an empty string
                                      onChange={(e) =>
                                        handleSocialMediaChange(
                                          index,
                                          "name",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select</option>{" "}
                                      {/* Default option */}
                                      <option value="Facebook">Facebook</option>
                                      <option value="Linkedin">Linkedin</option>
                                      <option value="Twitter">Twitter</option>
                                      <option value="Youtube">Youtube</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-6">
                                  <div className="form-group mb-1">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter URL"
                                      value={item.value || ""} // Ensure no undefined value
                                      onChange={(e) =>
                                        handleSocialMediaChange(
                                          index,
                                          "value",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {errors[`social_media_${index}_value`] && (
                                      <small className="text-danger">
                                        {errors[`social_media_${index}_value`]}
                                      </small>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-2 col-6">
                                  <div className="ad_buttons remove_bnt">
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() =>
                                        removeSocialMediaField(index)
                                      }
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group quest_mngs" id="browsers">
                            <label>
                              <span class="area_txt">
                                Certificate of Registration
                              </span>
                              <div className="contnt_detaills">
                                <img src="images/ques_drop.png" alt="" />
                                <div className="hvr_us_listst">
                                  <ul>
                                    <li>Upload Only</li>
                                    <li>Image</li>
                                    <li>PDF</li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                            <CommanImagePDf
                              name="ngo_certificate_of_registration"
                              setState={setUserExtraInfo}
                              state={userExtraInfo}
                            />

                            {errors.ngo_certificate_of_registration && (
                              <small className="text-danger">
                                {errors.ngo_certificate_of_registration}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group quest_mngs" id="browsers">
                            <label>
                              <span class="area_txt">
                                Articales of Association
                              </span>
                              <div className="contnt_detaills">
                                <img src="images/ques_drop.png" alt="" />
                                <div className="hvr_us_listst">
                                  <ul>
                                    <li>Upload Only</li>
                                    <li>Image</li>
                                    <li>PDF</li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                            <CommanImagePDf
                              name="articales_association"
                              setState={setUserExtraInfo}
                              state={userExtraInfo}
                            />
                            {errors.articales_association && (
                              <small className="text-danger">
                                {errors.articales_association}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group quest_mngs" id="browsers">
                            <label>
                              <span class="area_txt">
                                Operating License / Memorandum of Understanding
                              </span>
                              <div className="contnt_detaills">
                                <img src="images/ques_drop.png" alt="" />
                                <div className="hvr_us_listst">
                                  <ul>
                                    <li>Upload Only</li>
                                    <li>Image</li>
                                    <li>PDF</li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                            <CommanImagePDf
                              name="operating_license"
                              setState={setUserExtraInfo}
                              state={userExtraInfo}
                            />
                            {errors.operating_license && (
                              <small className="text-danger">
                                {errors.operating_license}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group quest_mngs" id="browsers">
                            <label>
                              <span class="area_txt">
                                Tax Identification Number / Exemption
                                Certificate
                              </span>
                              <div className="contnt_detaills">
                                <img src="images/ques_drop.png" alt="" />
                                <div className="hvr_us_listst">
                                  <ul>
                                    <li>Upload Only</li>
                                    <li>Image</li>
                                    <li>PDF</li>
                                    <li>Excel</li>
                                    <li>CSV</li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                            <CommanImagePDf
                              name="exemption_certificate"
                              setState={setUserExtraInfo}
                              state={userExtraInfo}
                            />
                            {errors.exemption_certificate && (
                              <small className="text-danger">
                                {errors.exemption_certificate}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Bank Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="bank_name"
                              placeholder="Enter Bank Name"
                              value={userExtraInfo.bank_name}
                              onChange={handleInputChange}
                            />
                            {errors.bank_name && (
                              <small className="text-danger">
                                {errors.bank_name}
                              </small>
                            )}
                          </div>
                        </div>{" "}
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Account Number</label>
                            <input
                              type="number"
                              className="form-control"
                              name="account_number"
                              placeholder="Enter Account Number"
                              value={userExtraInfo.account_number}
                              onChange={handleInputChange}
                            />
                            {errors.account_number && (
                              <small className="text-danger">
                                {errors.account_number}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Account Holder Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="account_holder_name"
                              placeholder="Enter Account Holder Name"
                              value={userExtraInfo.account_holder_name}
                              onChange={handleInputChange}
                            />
                            {errors.account_holder_name && (
                              <small className="text-danger">
                                {errors.account_holder_name}
                              </small>
                            )}
                          </div>
                        </div>{" "}
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Swift Code</label>
                            <input
                              type="text"
                              className="form-control"
                              name="swift_code"
                              placeholder="Enter Swift Code"
                              value={userExtraInfo.swift_code}
                              onChange={handleInputChange}
                            />
                            {errors.swift_code && (
                              <small className="text-danger">
                                {errors.swift_code}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group quest_mngs" id="browsers">
                            <label>
                              <span class="area_txt">Proof of Account</span>
                              <div className="contnt_detaills">
                                <img src="images/ques_drop.png" alt="" />
                                <div className="hvr_us_listst">
                                  <ul>
                                    <li>Upload Only</li>
                                    <li>Image</li>
                                    <li>PDF</li>
                                    <li>Excel</li>
                                    <li>CSV</li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                            <CommanImagePDf
                              name="proof_account"
                              setState={setUserExtraInfo}
                              state={userExtraInfo}
                            />
                            {errors.proof_account && (
                              <small className="text-danger">
                                {errors.proof_account}
                              </small>
                            )}
                          </div>
                        </div>
                        {/* <div class="col-lg-6">
                          <div class="form-group quest_mngs" id="browsers">
                            <label>
                              <span class="area_txt">
                                Identification of Contact (Government-issued ID)
                              </span>
                              <div className="contnt_detaills lastss">
                                <img src="images/ques_drop.png" alt="" />
                                <div className="hvr_us_listst">
                                  <ul>
                                    <li>Upload Only</li>
                                    <li>Image</li>
                                    <li>PDF</li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                            <CommanImagePDf
                              name="identification_of_point_of_contact"
                              setState={setUserExtraInfo}
                              state={userExtraInfo}
                            />
                            {errors.identification_of_point_of_contact && (
                              <small className="text-danger">
                                {errors.identification_of_point_of_contact}
                              </small>
                            )}
                          </div>
                        </div> */}
                        <div className="col-lg-12 spacetop mt-5">
                          <div class="form-group">
                            <button
                              type="submit"
                              value="Update Profile"
                              class="sub_mitess"
                            >
                              {buttonloader ? (
                                <ClipLoader color="#fff" size={"25"} />
                              ) : (
                                "Save"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdditionalInformation;
