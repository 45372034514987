import React, { useState } from "react";
import LogoIcon from "../../assets/Images/MiniLogo.png";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosClient.post("/users/forget-password", {
        email,
      });
      if (!response.data.error) {
        Swal.fire({
          icon: "success",
          title: "Reset Instructions Sent",
          text: "Please check your email for password reset instructions.",
        }).then(() => {
          localStorage.setItem("email", email);
          navigate("/reset-password");
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  return (
    <>
      <div className="container-fluid vh-100 d-flex justify-content-center login-container manages_top">
        <div className="row w-100 h-100">
          {/* Left side image */}
          <div className="col-md-6 d-none d-md-block">
            {/* <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <img src={LogoIcon} alt="WorldTrust" className="img-fluid" />
            <h5 className="mt-3">Helping with Donations</h5>
          </div> */}
          </div>

          {/* Right side login form */}
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center h-100">
            <div className="card p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center sc_lgo_allss">
              <div className="d-flex justify-content-center   ">
                <img src={LogoIcon} alt="WorldTrust" />
              </div>
              <h3 className="text-center">Forget Password</h3>
              <p className="text-center fw-bolder w-75 ">
                We'll send you instructions to reset your password.
              </p>
              <form className="w-50" onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label>Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="d-flex flex-column align-items-center w-100">
                  <button type="submit" className="btn bg-natural w-100">
                    Reset Password
                  </button>
                  <p className="text-start mt-3">
                    By clicking the button, you agree to the worldtrust Terms of
                    Service and acknowledge the Privacy Notice.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
